import React, {useState, useEffect} from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import ReqCur from '../components/reqCur'

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../components/checkout';
import Instalments from '../components/instalments.js'

import Contacts from '../components/contactform';
import Covid from '../components/covid';

import css from '../pics/responsive_web_design_with_css3_barcelona_code_school.png'

const CSScourse = () => {

	let [courses, setCourses] = useState({
		durationdays: 5,
		durationweeks: 1,
		fulldesc1: "5 days, 40 hours, 9:00-18:00, in-person, in English, full-time.",
		fulldesc2: "Registration payment 750€",
		fullprice: 750,
		image: "https://barcelonacodeschool.com/static/responsive_web_design_with_css3_barcelona_code_school-28958f08c2a6c0bab1cae57755add173.png",
		priceoptions: [],
		shortdesc: "Learn how to build modern websites for any device",
		signupprice: 750,
		startdates: [
			'January 6, 2025', 
			'March 17, 2025', 
			'May 26, 2025'
		],
		starttime: "9:00",
		tag: "css",
		title: "Responsive web design with CSS3",
	})
	
	// useEffect(()=>{
	// 	let getData = async () => {
	// 		let data = await axios.get('https://barcelonacodeschool.com/events/css')
	// 		setCourses(data.data)
	// 		console.log("Courses==> ", data.data)
	// 	}
	// 	getData()
	// },[])

	let renderCourses =()=> {
		return courses.startdates.map((date,i)=>(
			<div key={i} className="whiteCard flex300 bottomSpace" >
			<span>
			<h2>{date}</h2>
			<h4>{courses.title}</h4>
			<p>{courses.shortdesc}</p>
			</span>
			<span>
			<p>{courses.fulldesc1}</p>
			<p>{courses.fulldesc2}</p>
			{date.includes("March 11, 2024")&& <div className='highlighter'><h2>Join the waitlist</h2><p className='finePrint' style={{display:'block'}}>If one of the places will become available you will be notified straight away.</p> <p className='finePrint' style={{display:'block'}}>If not, you will get a full refund.</p></div>}
			{date.includes("March 11, 2024")?<Link to="/register" state={{course: courses.title, date:date, cost:courses.signupprice, images:[courses.image]}}>
			<button style={{'marginBottom': '1em'}}>Join the waitlist</button>
			</Link>:<Link to="/register" state={{course: courses.title, date:date, cost:courses.signupprice, images:[courses.image]}}>
			<button style={{'marginBottom': '1em'}}>Sign up</button>
			</Link>}

			


			<p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>
			</span>
			</div>
			))
	}

	return <div className="flexContent">
	<Helmet

	title="Responsive web design with CSS3"
	meta={[
		{ name: 'description', content: 'Responsive web design with CSS course. Learn how to build modern websites which work great on every user device. 5-day course, 20 hours of classwork, 3 projects done.' },
		{ name: 'keywords', content: 'responsive web design with css course, css3 course, learn css' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
		script={[
			{ "src": "https://js.stripe.com/v3/" },
			]}
			/>
			<h1 className='transparentContainer flex900'>Responsive web design with CSS3</h1>
			
			<img 
			className='banners flex1200 offsetImage'
			src={css} 
			alt='Responsive web design with CSS3 course in Barcelona Code School'/>


			<h2 className='transparentContainer flex450'>Learn how to create modern responsive websites for any devices with the latest CSS</h2>

			<h2 className='transparentContainer flex225'>5 days, 40 hours, full-time, in-person</h2>

			<article className='transparentContainer flex225'>
			<h2>Tuition</h2>
			<div className='whiteCard'>
			<h2>750€</h2>
			<p>Sign up online and reserve your seat.</p>
			<a href='#cohorts'><button>Check the dates</button></a>
			<Instalments/>
			</div>
			</article>

			<article className='transparentContainer flex450'>
			<h2>What you'll learn</h2>
			<div className='whiteCard'>
			<ul className='neonList'>
			<li>Creating all kinds of layout of any complexity</li>
			<li>Using media queries to switch between desktop/tablet/mobile versions of your website</li>
			<li>Understanding simple and combined selectors</li>
			<li>Absolute/relative length units (the good, the bad and the ugly)</li>
			<li>The holy box model</li>
			<li>Positioning, stacking, floating your elements with/wthout taking them out of the natural document's flow</li>
			<li>Using margins/paddings/borders</li>
			<li>Creating layouts with CSS Grid (amen to that)</li>
			<li>Flexbox properties and why they rule for the native mobile apps</li>
			<li>Cross-browser testing, resetting, normalizing, debugging, validating</li>
			<li>Browser-based development</li>
			</ul>
			</div>
			</article>



			<article className='transparentContainer flex225'>
			<h2>The outcomes</h2>
			<div className='whiteCard'>

			<ul className='neonList'>
			<li>You will be able to build a layout for any kind of website</li>
			<li>It would be a responsive layout working great on any screen size</li>
			<li>You will know how to use custom CSS with pages generated by any type of CMS or in other cases where you don't have control over classes/ids by using combined selector and DOM logic</li>
			<li>A portfolio of several projects created with pure CSS</li>
			</ul>
			</div>
			</article>






			<article className="transparentContainer flex450">
			<h2>Prerequisites</h2>
			<div className='whiteCard'>
			<ul className='neonList'>
			<li>You will need a laptop, the OS doesn't matter, MacOS, Windows, Linux is all fine</li>
			<li>Google Chrome</li>
			<li>Any code editor: Sublime Text, Visual Studio Code, Atom, etc...</li>
			<li>An account with GitLab/GitHub (free account is fine!)</li>
			<li>If you can install Git it would be amazing. (Instructions for <a href='https://git-scm.com/book/en/v2/Getting-Started-Installing-Git'>Mac</a>, <a href='https://gitforwindows.org/'>Windows</a>, <a href='https://git-scm.com/book/en/v2/Getting-Started-Installing-Git'>Linux</a>)</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex300">
			<h2>Who is this course for?</h2>
			<div className='whiteCard'>
			<ul className='neonList'>
			<li>Students thinking about building their static websites, landing pages, prototypes</li>
			<li>Developers aiming at learning CSS to add to their skill set</li>
			<li>Designers willing to learn how to implement mockups in code</li>
			<li>Beginners starting to learn HTML/CSS</li>
			</ul>
			</div>
			</article>


			<article id="cohorts" className="transparentContainer flex1200 flexContent">
			<h2>Access</h2>
			<div className='flexContent'>
			{renderCourses()}
			</div>
			</article>

			

			<article className='flex450'>
			<Contacts title={'Inquiry about the course'} subject={'Inquiry about CSS3 course'} />
			</article>

			</div>
		}

		export default CSScourse
